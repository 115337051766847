import { Authorization } from '@gower/cm-mdp-sdk/authorizations';
import * as Logos from './agreements-logos';
import React, { SVGAttributes } from "react";
import { FiCameraOff } from '@gower/react-ui/icons';
import { Colors } from '@gower/react-ui';

const AgreementLogos = {
  [Authorization.VO.Agreement.SAMI.Id]: Logos.LogoSAMI,
  [Authorization.VO.Agreement.IOMA.Id]: Logos.LogoIOMA,
  [Authorization.VO.Agreement.AMFFA.Id]: Logos.LogoAMFFA,
  [Authorization.VO.Agreement.COMEI.Id]: Logos.LogoCOMEI,
  [Authorization.VO.Agreement.OSAPM.Id]: Logos.LogoOSAPM,
  [Authorization.VO.Agreement.CASA.Id]: Logos.LogoCASA,
  [Authorization.VO.Agreement.UnionPersonal.Id]: Logos.LogoUP,
  [Authorization.VO.Agreement.OSARPyH.Id]: Logos.LogoOSARPY,
  [Authorization.VO.Agreement.OSPATCAB.Id]: Logos.LogoPATCAB,
  [Authorization.VO.Agreement.SANCOR.Id]: Logos.LogoSANCOR,
  [Authorization.VO.Agreement.AMEBPBA.Id]: Logos.LogoAMEBPBA,
  [Authorization.VO.Agreement.FEDERADA.Id]: Logos.LogoFEDERADA,
  [Authorization.VO.Agreement.AMTAR.Id]: Logos.LogoAMTAR,
  [Authorization.VO.Agreement.OSPF.Id]: Logos.LogoOSPF,
  [Authorization.VO.Agreement.OSPIA.Id]: Logos.LogoOSPIA,
  [Authorization.VO.Agreement.OSPTV.Id]: Logos.LogoOSPTV,
  [Authorization.VO.Agreement.PREVENCION.Id]: Logos.LogoPREVENCION,
  [Authorization.VO.Agreement.ELEVAR.Id]: Logos.LogoELEVAR,
  [Authorization.VO.Agreement.MEDICUS.Id]: Logos.LogoMEDICUS,
  [Authorization.VO.Agreement.OSPEPBA.Id]: Logos.LogoOSPEBPBA,
  [Authorization.VO.Agreement.SIA.Id]: Logos.LogoSIA,
};


export type AgreementLogoProps = Omit<SVGAttributes<any>, 'dangerouslySetInnerHTML'> & {
  agreement: string;
  size?: string;
};

export const AgreementLogo: React.FC<AgreementLogoProps> = ( { agreement, ...props } ) => {

  const Logo = AgreementLogos[agreement];

  if ( Logo )
    return <Logo {...props} />;

  return <FiCameraOff size={32} color={Colors['surface-4']} {...props} />;
};